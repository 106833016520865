[v-cloak]
	display: none !important

// Fancybox setup
.fancybox-is-open .fancybox-bg
	background-color: black
	opacity: .8

html
	height: 100%
	font-size: 16px
	scroll-behavior: smooth
	overflow: hidden
	& *
		font-family: 'CeraPro', sans-serif
body
	position: fixed
	top: 0
	left: 0
	width: 100%
	height: 100%
	background-color: white
	padding: 0
	margin: 0
	color: $black
	display: flex
	flex-direction: column
	+Vscroll
	&.fancybox-active
		overflow: hidden
	&.mobilemenu-opened, &.popup-opened
		position: relative
		overflow: hidden


select, input, textarea, button
	appearance: none

ul, ol, h1, h2, h3, h4, a
	list-style: none
	padding: 0
	margin: 0
	text-decoration: none

.wrap
	width: 100%
	height: 100%
	position: relative
	overflow: hidden
	display: flex
	flex-direction: column

	& main
		display: flex
		flex-direction: column
		width: 100%
		flex-grow: 1

.wrapper
	width: 100%
	max-width: 1766px
	margin: 0 auto
	position: relative
	padding: 0 calc(16px + env(safe-area-inset-right)) 0 calc(16px + env(safe-area-inset-left))
	display: flex
	flex-direction: column
	+media-until(1024px)
		padding-top: env(safe-area-inset-top)


section
	margin: 0

// buttons,inputs=================================================================================================================================

*, *::after, *::before
	box-sizing: border-box
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0)
	-webkit-tap-highlight-color: transparent
button
	cursor: pointer
	background: none
	border: none
	&:active
		outline: none
	&:focus
		outline: none

input, textarea
	border: none
a
	color: inherit
	&:active
		outline: none
	&:focus
		outline: none

input:focus, input:active, select:focus, select:active, textarea:focus, textarea:active
	outline: none

// text==============================================================================================================================================
p
	line-height: 1.6
	color: inherit
	margin: 0
	padding: 0

h1
	font-weight: bold
	font-size: 60px
	line-height: 100%
// h2
// 	font-weight: bold
// 	font-size: 30px
// 	line-height: 1
// h3
// 	font-weight: bold
// 	font-size: 52px
// 	line-height: 1.25
// h4
// 	line-height: 1.25
// 	font-weight: 400
// 	font-size: 24px
// h5
// 	font-size: 20px
// 	font-weight: 500
// 	line-height: 1.35

// ANIMATIONS====================================

.fadeInUp
	opacity: 0
	transform: translate3d(0,35px,0)
	transition: opacity .5s linear,transform .7s ease
	transition-delay: .25s
	&.js-visible
		opacity: 1
		transform: translate3d(0,0,0)

.fadeInLeft
	opacity: 0
	transform: translate3d(35px,0,0)
	transition: opacity .5s linear,transform .7s ease
	transition-delay: .25s
	&.js-visible
		opacity: 1
		transform: none
.fadeInRight
	opacity: 0
	transform: translate3d(-35px,0,0)
	transition: opacity 1s linear,transform 1.1s ease
	transition-delay: .25s
	&.js-visible
		opacity: 1
		transform: none
.fadeInBot
	opacity: 0
	transform: translate3d(0, -35px,0)
	transition: opacity 1s linear,transform 1.1s ease
	transition-delay: .25s
	&.js-visible
		opacity: 1
		transform: none

.fade-enter-active, .fade-leave-active
	transition: opacity .9s
.fade-enter, .fade-leave-to
	opacity: 0

.fadeBot-enter-active, .fadeBot-leave-active, .fadeTop-enter-active, .fadeTop-leave-active
	transition: opacity .5s, transform 1.1s

.fadeBot-enter, .fadeBot-leave-to
	opacity: 0
	transform: translateY(-50px)

.fadeTop-enter, .fadeTop-leave-to
	opacity: 0
	transform: translateY(50px)
