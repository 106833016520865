.main-helper
    z-index: 20
    position: relative
    margin-left: auto
    &__text
        background-color: #fff
        padding: 25px 30px
        font-size: 14px
        border-radius: 15px 15px 0px 15px
        max-width: 270px
        width: 100%
        position: absolute
        bottom: calc(100% + 20px)
        right: 20px
        transform: scale(0)
        animation: scale .5s forwards
    &__button
        cursor: pointer
        animation: scale .5s forwards
        size: 50px
        border-radius: 50%
        background: linear-gradient(360deg, #1D4CB4 0%, #468CD3 100%)
        filter: drop-shadow(0 0px 10px $main)
        display: flex
        align-items: center
        justify-content: center
        position: relative
        &::before
            content: ''
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%,-50%)
            pointer-events: none
            size: 64px
            border: 1px solid rgba(255, 255, 255, 0.2)
            border-radius: 50%

        & span
            size: 21px
            background-image: url("data:image/svg+xml,%3Csvg width='22' height='20' viewBox='0 0 22 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.4901 9.58343C11.4901 12.5892 11.4841 15.5949 11.4961 18.6007C11.4961 18.936 11.2446 19.1934 10.9512 19.1396C10.8315 19.1156 10.7117 19.0438 10.6159 18.9599C8.88552 17.5229 7.15512 16.0859 5.43071 14.6309C5.1912 14.4333 4.96368 14.3495 4.65233 14.3495C3.59253 14.3675 2.53273 14.3555 1.46695 14.3555C0.628693 14.3555 0 13.7268 0 12.8945C0 10.6791 0 8.45777 0 6.24237C0 5.41609 0.628693 4.7874 1.45497 4.7874C2.61656 4.7874 3.77814 4.7874 4.93973 4.78141C5.02355 4.78141 5.13133 4.74549 5.1912 4.6916C7.01142 3.18274 8.82565 1.66789 10.6399 0.153038C10.8015 0.0153241 10.9812 -0.0505389 11.1907 0.0452619C11.4123 0.14705 11.4841 0.320689 11.4841 0.554204C11.4901 3.55995 11.4901 6.57169 11.4901 9.58343ZM10.5141 1.53017C10.4543 1.57807 10.4123 1.60202 10.3764 1.63196C8.8017 2.94324 7.22697 4.25451 5.65225 5.57177C5.50855 5.69152 5.36484 5.74541 5.17923 5.74541C3.95777 5.73942 2.73631 5.74541 1.51485 5.74541C1.14961 5.74541 0.963996 5.93102 0.963996 6.29626C0.963996 8.47573 0.963996 10.6612 0.963996 12.8407C0.963996 13.2119 1.14961 13.3975 1.52682 13.3975C2.7423 13.3975 3.95777 13.3975 5.17324 13.3975C5.36484 13.3975 5.51453 13.4574 5.66422 13.5771C7.23296 14.8884 8.80768 16.1997 10.3764 17.505C10.4123 17.5349 10.4602 17.5648 10.5201 17.6008C10.5141 12.2359 10.5141 6.90101 10.5141 1.53017Z' fill='white' /%3E%3Cpath d='M19.5 4C21.5 7 21.5 12 19.5 15' stroke='white' stroke-linecap='round' stroke-linejoin='round' /%3E%3Cpath d='M17 6C18 8 18 11 17 13' stroke='white' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E")
            background-repeat: no-repeat
            background-size: contain

@keyframes scale
    0%
        transform: scale(0)

    100%
        transform: scale(1)
