$desktop-min: 1024px
$widescreen-min: 1440px
$mobile-max: 425px
$tablet-max: 768px

// -==========================================================================================================
@mixin from-tablet()
	@media screen and ( min-width: $tablet-max)
		@content

@mixin from-desktop()
	@media screen and ( min-width: $desktop-min)
		@content

@mixin from-widescreen()
	@media screen and ( min-width: $widescreen-min)
		@content

@mixin from-mob()
	@media screen and ( min-width: $mobile-max + 1 )
		@content

// -==========================================================================================================
@mixin while-mob()
	@media screen and ( max-width: $mobile-max  )
		@content

@mixin until-tablet()
	@media screen and ( max-width: $tablet-max)
		@content

@mixin until-desktop()
	@media screen and ( max-width: $desktop-min - 1)
		@content

@mixin until-widescreen()
	@media screen and ( max-width: $widescreen-min - 1)
		@content

// -==========================================================================================================
@mixin media-until($width)
	@media screen and ( max-width: $width)
		@content

@mixin media-from($width)
	@media screen and ( min-width: $width)
		@content

// -==========================================================================================================
@mixin placeholder()
	&::-webkit-input-placeholder
		@content
	&:-moz-placeholder
		@content
	&::-moz-placeholder
		@content
	&:-ms-input-placeholder
		@content
// -==========================================================================================================
@mixin Hscroll()
	/* 1 - скроллбар */
	&::-webkit-scrollbar
		appearance: none
		-webkit-appearance: none
		&:horizontal
			height: 10px
	&::-webkit-scrollbar-button /* 2 - кнопка */
	&::-webkit-scrollbar-track /* 3 - трек */
		border-radius: 2px
		background: #F2F2F2
		// box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.56)
	&::-webkit-scrollbar-track-piece /* 4 - видимая часть трека */
	&::-webkit-scrollbar-thumb /* 5 - ползунок */
		background-color: #C4C4C4
		border-radius: 2px
		border: 3px solid #F2F2F2
	&::-webkit-scrollbar-corner	/* 6 - уголок */
	&::-webkit-resizer /* 7 - изменение размеров окна*/

@mixin Vscroll()
	&::-webkit-scrollbar /* 1 - скроллбар */
		width: 5px
		appearance: none
		-webkit-appearance: none
	&::-webkit-scrollbar-track /* 3 - трек */
		border-radius: 2px
		background: $grey
	&::-webkit-scrollbar-thumb /* 5 - ползунок */
		border-radius: 2px
		background-color: $main
		border: 1px solid $main

@mixin btn()
	height: 60px
	background: linear-gradient(90deg, #1D4CB4 0%, #468CD3 100%)
	backdrop-filter: blur(20px)
	border-radius: 30px
	padding: 0 30px
	color: white
	display: flex
	align-items: center
	justify-content: center
	transition: background-color .3s
	+media-until(1450px)
		height: 48px
.main-btn
	+btn
	
@mixin btn-arrow()
	height: 60px
	background: linear-gradient(90deg, #1D4CB4 0%, #468CD3 100%)
	backdrop-filter: blur(20px)
	border-radius: 30px
	padding: 0 30px
	color: white
	display: flex
	align-items: center
	justify-content: center
	transition: background-color .3s
	+media-until(1450px)
		height: 48px
	&::after
		transition: transform .3s
		content: ''
		size: 7px
		margin-left: 13px
		background: url("data:image/svg+xml,%3Csvg width='6' height='7' viewBox='0 0 6 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.41406 3.70703L0.898594 6.60574L1.89859 3.70703L0.898594 0.808327L5.41406 3.70703Z' fill='white' /%3E%3C/svg%3E") no-repeat center
		background-size: contain
	&:hover
		// background-color: #183e91
		&::after
			transform: translateX(5px)
