.inner-page
    size: 100%
    position: fixed
    top: 0
    left: 0
    z-index: 20
    background-color: transparent
    display: flex
    flex-direction: column
    padding: 195px 0 61px
    &::before, &::after
        position: absolute
        top: 0
        content: ''
        width: 50%
        height: 0%
        pointer-events: none
        z-index: 0
    &::after
        left: 0
        background-color: #EBEEF7
        animation: height .7s forwards
    &::before
        animation: height .7s .7s forwards
        right: 0
        bottom: 0
        top: unset
        background-color: white
    & .wrapper
        height: 100%
        flex-grow: 1
        z-index: 1
        display: grid
        grid-template-columns: 1fr 1fr
        align-items: center
        column-gap: 100px
        row-gap: 22px
        +media-until(1279px)
            column-gap: 0px
        &.mobile
            max-width: 100%
            min-width: 100%
            overflow-y: auto
            & .inner-page__content-wrapper
                height: auto
                min-height: unset
                max-height: unset
                overflow-y: unset
    &__close
        z-index: 3
        cursor: pointer
        size: 60px
        background: linear-gradient(90deg, rgb(29, 76, 180) 0%, rgb(70, 140, 211) 100%)
        border-radius: 50%
        position: absolute
        opacity: 0
        top: 50px
        left: 50%
        transform: translateX(-50%)
        animation: fadeIn2 .5s 1.8s forwards
        +media-until(1450px)
            size: 48px
        & span
            background-color: #fff
            width: 12px
            height: 2px
            position: absolute
            top: 50%
            left: 50%
            +media-until(1450px)
                width: 8px
            &:first-of-type
                transform: translate(-50%,-50%) rotate(-45deg)
            &:last-of-type
                transform: translate(-50%,-50%) rotate(45deg)
    &__prev, &__next
        z-index: 3
        cursor: pointer
        height: 60px
        position: absolute
        top: 50px
        font-size: 14px
        color: rgb(255, 255, 255)
        display: flex
        align-items: center
        justify-content: center
        min-width: 220px
        opacity: 0
        padding: 13px 28px 13px 18px
        background: linear-gradient(90deg, rgb(29, 76, 180) 0%, rgb(70, 140, 211) 100%)
        border-radius: 100px
        animation: fadeIn 0.5s ease 1.8s 1 normal forwards running
        +media-until(1450px)
            height: 48px
            font-size: 12px
    &__prev
        left: 77px
        &::before
            content: ""
            width: 10px
            height: 10px
            display: block
            margin-right: 13px
            background: url("data:image/svg+xml,%3Csvg width='8' height='14' viewBox='0 0 8 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-1.96694e-06 7.00021L5.99116 1.00896C6.35145 0.648664 6.93057 0.653613 7.28466 1.02002C7.63874 1.38641 7.63371 1.97552 7.27342 2.33581L1.98936 7.61995C1.59883 8.01048 0.971122 8.00511 0.587328 7.60797L-1.96694e-06 7.00021Z' fill='white'/%3E%3Cpath d='M0.610516 6.38959C0.995975 6.00407 1.61387 6.00356 1.99973 6.38844L7.17083 11.5465C7.5361 11.9109 7.53638 12.5093 7.17146 12.8742C6.81589 13.2298 6.24596 13.2302 5.89005 12.8752L-1.96694e-06 7.00021L0.610516 6.38959Z' fill='white'/%3E%3C/svg%3E%0A") center no-repeat
    &__next
        right: 77px
        &::after
            content: ""
            width: 10px
            height: 10px
            display: block
            margin-left: 13px
            transform: rotate(180deg)
            background: url("data:image/svg+xml,%3Csvg width='8' height='14' viewBox='0 0 8 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-1.96694e-06 7.00021L5.99116 1.00896C6.35145 0.648664 6.93057 0.653613 7.28466 1.02002C7.63874 1.38641 7.63371 1.97552 7.27342 2.33581L1.98936 7.61995C1.59883 8.01048 0.971122 8.00511 0.587328 7.60797L-1.96694e-06 7.00021Z' fill='white'/%3E%3Cpath d='M0.610516 6.38959C0.995975 6.00407 1.61387 6.00356 1.99973 6.38844L7.17083 11.5465C7.5361 11.9109 7.53638 12.5093 7.17146 12.8742C6.81589 13.2298 6.24596 13.2302 5.89005 12.8752L-1.96694e-06 7.00021L0.610516 6.38959Z' fill='white'/%3E%3C/svg%3E%0A") center no-repeat
    &__toggles
        position: absolute
        top: 85px
        left: 50%
        z-index: 2
        padding-left: 95px
        font-size: 14px
        line-height: 100%
        letter-spacing: 0.04em
        text-transform: uppercase
        color: #ACB7D8
        animation: fadeIn 0.5s 1.6s forwards
        opacity: 0
        +media-until(1450px)
            top: 125px
        +media-until(1279px)
            padding-left: 25px
    &__toggle
        display: flex
        align-items: center
        cursor: pointer
        &:not(:last-child)
            margin-bottom: 15px
        &::before
            content: ""
            width: 20px
            height: 20px
            display: block
            margin-right: 20px
            background: url("data:image/svg+xml,%3Csvg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.707 5.85352L0.749979 11.6035L5.73362 5.85352L0.749979 0.103537L12.707 5.85352Z' fill='%23ACB7D8'/%3E%3C/svg%3E%0A") no-repeat center
        &.active
            color: #1d4cb4
            &::before
                background: url("data:image/svg+xml,%3Csvg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.707 5.85352L0.749979 11.6035L5.73362 5.85352L0.749979 0.103537L12.707 5.85352Z' fill='url(%23paint0_linear)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='7.94956' y1='1.09749' x2='-0.903952' y2='9.95101' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23004ABB'/%3E%3Cstop offset='0.16' stop-color='%230051BB'/%3E%3Cstop offset='0.4' stop-color='%230065BB'/%3E%3Cstop offset='0.51' stop-color='%230071BB'/%3E%3Cstop offset='0.6' stop-color='%230777C1'/%3E%3Cstop offset='1' stop-color='%23238FDB'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A") no-repeat center
    &__image
        opacity: 0
        animation: fadeIn .5s 1.4s forwards
        align-self: center
        position: fixed
        top: 0
        left: 0
        width: 50%
        height: 100%
        z-index: 2
        & .swiper-buttons
            position: absolute
            bottom: 70px
            right: 50px
            display: flex
            align-items: center
            justify-content: flex-end
        & .swiper-button
            &-next
                transform: scaleX(-1)
                margin-left: 20px
            &-prev, &-next
                position: static
                color: white
                bottom: 70px
                size: 50px
                background: linear-gradient(90deg, #1D4CB4 0%, #468CD3 100%)
                border-radius: 50%
                display: flex
                align-items: center
                justify-content: center
                &::before
                    display: none
                &::after
                    content: ''
                    size: 8px
                    border-left: 1px solid currentColor
                    border-bottom: 1px solid currentColor
                    transform: rotate(45deg)
                    transition: transform .3s
        & .swiper-container, & .swiper-wrapper, & .swiper-slide
            size: 100%
            
        & img
            size: 100%
            object-fit: cover
            opacity: 0
            animation: fadeIn3 0.5s ease 0s 1 normal forwards running
    &__contacts
        display: flex
        align-items: center
        opacity: 0
        grid-column: 2 / auto
        padding: 0px 45px
        animation: fadeIn 0.5s ease 1.6s forwards
        align-self: end
        +media-until(1450px)
            padding: 0 25px
        &-callback
            cursor: pointer
            user-select: none
            height: 60px
            color: white
            display: flex
            align-items: center
            justify-content: center
            backdrop-filter: none
            background: linear-gradient(90deg, rgb(29, 76, 180) 0%, rgb(70, 140, 211) 100%)
            border-radius: 30px
            padding: 0px 30px
            transition: background-color 0.3s ease 0s
            &:not(:last-child)
                margin-right: 38px
                +media-until(1450px)
                    margin-right: 20px
            +media-until(1450px)
                padding: 0 20px
                height: 48px
                font-size: 12px

    &__read-more
        padding: 0px 45px
        position: fixed
        bottom: 0
        left: 0
        height: 100%
        width: 100%
        display: flex
        flex-direction: column
        justify-content: end
        z-index: 3
        pointer-events: none
        span
            margin-top: auto
            cursor: pointer
            pointer-events: all
            font-size: 14px
            display: flex
            align-items: center
            justify-content: flex-start
            &::after
                content: ""
                width: 8px
                height: 4px
                display: inline-block
                margin-left: 16px
                background: url("data:image/svg+xml,%0A%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.64645 4.35355C3.84171 4.54882 4.15829 4.54882 4.35355 4.35355L7.53553 1.17157C7.7308 0.976311 7.7308 0.659728 7.53553 0.464466C7.34027 0.269204 7.02369 0.269204 6.82843 0.464466L4 3.29289L1.17157 0.464466C0.976311 0.269204 0.659728 0.269204 0.464466 0.464466C0.269204 0.659728 0.269204 0.976311 0.464466 1.17157L3.64645 4.35355ZM3.5 3L3.5 4L4.5 4L4.5 3L3.5 3Z' fill='%23575656'/%3E%3C/svg%3E%0A") no-repeat center
                transition: transform .4s ease

    &__content-wrapper
        align-self: start
        grid-column-start: 2
        opacity: 0
        animation: fadeIn .5s 1.6s forwards
        height: 100%
        max-height: 600px
        overflow-y: hidden
        transition: max-height 0.3s ease 0s
        position: relative
        padding-bottom: 30px
        will-change: max-height
        &::after
            content: ""
            display: block
            width: 100%
            height: 100px
            position: absolute
            bottom: 0px
            left: 0px
            opacity: 1
            background: linear-gradient(0deg, white 0%, white 50%, rgba(255, 255, 255, 0) 100%)
            transition: opacity 0.5s ease 0s
        &.active
            max-height: 100%
            & .inner-page__content
                overflow-y: auto
            &::after
                opacity: 0
                z-index: -1
            & .inner-page__read-more
                margin-top: auto
                span
                    &::after
                        transform: rotate(180deg)

    &__content
        padding: 0 45px
        font-size: 14px
        max-height: 100%
        height: 100%
        overflow-y: hidden
        +Vscroll
        +media-until(1450px)
            padding: 0 25px
        & > *
            animation: fadeIn 0.7s forwards

        & h2
            color: $main
            font-size: 40px
            margin-bottom: 50px
            +media-until(1680px)
                font-size: 32px
                margin-bottom: 30px
        & p
            max-width: 600px
            font-size: 14px
            line-height: 1.8
            margin-bottom: 20px
        & ol
            margin-bottom: 30px
            padding-left: 20px
            list-style-type: decimal
            & li
                font-size: 14px
                &:not(:last-of-type)
                    margin-bottom: 15px
        & ul
            margin-top: 10px
            margin-bottom: 30px
            padding-left: 20px
            & li
                font-size: 14px
                position: relative
                &:not(:last-of-type)
                    margin-bottom: 15px
                &::before
                    flex-shrink: 0
                    left: -18px
                    top: 6px
                    position: absolute
                    content: ''
                    margin-right: 15px
                    size: 6px
                    border-radius: 50%
                    background-color: $main
            &.advantages
                padding-left: 0
                margin-bottom: 60px
                +media-until(1680px)
                    margin-bottom: 24px
                & li
                    display: flex
                    align-items: center
                    font-size: 14px
                    color: $main
                    font-weight: bold
                    &::before
                        display: none
                    &:not(:last-of-type)
                        margin-bottom: 44px
                        +media-until(1680px)
                            margin-bottom: 24px
                    
                    & img
                        size: 88px
                        background: #FFFFFF
                        box-shadow: 0px 4px 30px #EBEEF7
                        border-radius: 50%
                        display: flex
                        object-fit: none
                        object-position: center
                        margin-right: 20px
                        +media-until(1680px)
                            size: 60px
                    & span
                        font-size: 14px
                        color: $main
                        font-weight: bold

@keyframes height
    0%
        height: 0

    100%
        height: 100%

@keyframes fadeIn
    0%
        opacity: 0
        transform: translateY(40px)

    100%
        opacity: 1
        transform: translateY(0px)

@keyframes fadeIn2
    0%
        opacity: 0
        transform: translateY(40px) translateX(-50%)

    100%
        opacity: 1
        transform: translateY(0px) translateX(-50%)

@keyframes fadeIn23
    0%
        opacity: 0

    100%
        opacity: 1
