.header
    padding-top: 55px
    width: 100%
    top: 0
    left: 0
    position: absolute
    z-index: 20
    &.black
        & .header
            &__logo
                filter: invert(1)
            &__type-item
                color: black
            &__contacts-phone
                color: black
    & .wrapper
        justify-content: space-between
        flex-direction: row
    &__logo
        cursor: pointer
    &__contacts
        display: flex
        align-items: center
        &-phone
            font-size: 18px
            color: white
            margin-right: 40px
            &.white
                color: white !important
        &-callback
            cursor: pointer
            user-select: none
            +btn
    &__type
        user-select: none
        display: flex
        align-items: center
        position: absolute
        top: 0
        left: 50%
        transform: translate(-50%)
        &-item
            flex-shrink: 0
            font-size: 14px
            cursor: pointer
            letter-spacing: 0.06em
            display: flex
            align-items: center
            color: white
            padding: 0 30px
            height: 60px
            transition: background-color .3s, border-color .3s
            text-transform: uppercase
            border-radius: 30px
            border: 1px solid rgba(255, 255, 255, 0.4)
            &.active
                backdrop-filter: blur(5px)
                border-color: transparent
                background: rgba(255, 255, 255, 0.4)
            &:not(:last-of-type)
                margin-right: 35px

    .back-link
        cursor: pointer
        color: #fff
        align-self: center
        transition: opacity .3s
        display: flex
        &::before
            content: ''
            position: relative
            display: block
            flex: none
            width: 16px
            height: 20px
            margin-right: 16px
            background-image: url("data:image/svg+xml,%3Csvg width='18' height='8' viewBox='0 0 18 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 4.5C17.2761 4.5 17.5 4.27614 17.5 4C17.5 3.72386 17.2761 3.5 17 3.5L17 4.5ZM0.646446 3.64645C0.451185 3.84171 0.451185 4.15829 0.646446 4.35355L3.82843 7.53554C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976312 4.7308 0.659729 4.53553 0.464467C4.34027 0.269205 4.02369 0.269205 3.82843 0.464467L0.646446 3.64645ZM17 3.5L1 3.5L1 4.5L17 4.5L17 3.5Z' fill='white'/%3E%3C/svg%3E%0A")
            background-repeat: no-repeat
            background-position: center
        &:hover
            opacity: .7
        &--inside
            filter: invert(1)