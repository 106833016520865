.popup
    z-index: 55
    background: #EBEEF7
    width: 100%
    height: 100%
    position: fixed
    top: 0
    left: 0
    transition: opacity .3s
    display: none
    justify-content: center
    opacity: 0
    &.opening
        display: flex
        opacity: 1
    &.opened
        //animation: fadeIn .3s
        opacity: 1
        display: flex
        & .popup__block
            transform: scale(1)
    &.closing
        opacity: 0
    &__close
        z-index: 3
        cursor: pointer
        width: 60px
        height: 60px
        background: linear-gradient(90deg, #1D4CB4 0%, #468CD3 100%)
        border-radius: 50%
        position: absolute
        top: 50px
        left: 50%
        transform: translateX(-50%)
        +until-tablet
            top: 20px
            left: 90%
        & span
            pointer-events: none
            position: absolute
            display: block
            background-color: #fff
            width: 12px
            height: 2px
            color: black
            top: 50%
            left: 50%
            +until-tablet
                background-color: black
            &:first-of-type
                transform: translate(-50%, -50%) rotate(45deg)
            &:last-of-type
                transform: translate(-50%, -50%) rotate(-45deg)
    &__block
        transform: scale(0.1)
        position: relative
        width: 100%
        max-height: 100%
        max-width: 630px
        padding: 60px 70px
        transition: transform .4s
        background: #FFFFFF
        border: 1px solid #ECEFF5
        display: flex
        flex-direction: column
        justify-content: center
        @media (max-height: 600px)
            margin-bottom: 25px
        +until-tablet
            padding: 25px
            padding-top: 40px
    &__content
        width: 100%
        display: flex
        flex-direction: column
        align-items: center
    &__title
        font-weight: bold
        font-size: 30px
        line-height: 183.4%
        margin-bottom: 45px
        text-align: center
        +until-tablet
            margin-bottom: 20px
    &__notion
        text-align: center
        max-width: 350px
        width: 100%
        font-size: 12px
        opacity: .5
        color: #C0C8DC
        position: absolute
        bottom: 48px
        left: 50%
        transform: translate(-50%)

    &__form
        width: 100%
        display: grid
        grid-gap: 17px
        & > button
            width: 200px
            justify-self: center
        &-inputs
            display: grid
            width: 100%
            grid-gap: 30px 40px
            margin-bottom: 20px
            grid-template-columns: repeat(6, 1fr)
            +until-tablet
                grid-template-columns: 1fr
        &-input
            grid-column: 1/-1
            display: flex
            flex-direction: column
            align-items: flex-start

            & span
                font-size: 14px
                margin-bottom: 10px
            &-error
                font-size: 12px
                color: $red
                margin-top: 5px
            & input:not([type=radio])
                height: 56px
            & textarea
                resize: vertical
                padding-top: 10px
            & input:not([type=radio]), textarea
                padding-left: 20px
                width: 100%
                font-size: 14px
                background: #FFFFFF
                border: 1px solid #DADFEE
                border-radius: 3px

@keyframes fadeIn
    0%
        opacity: 0
    100%
        opacity: 1
