.inside
    background-color: #EBEEF7
    size: 100%
    z-index: 2
    position: absolute
    top: 0
    left: 0
    display: flex
    flex-direction: column
    & > .wrapper
        max-width: 1612px
    &__back
        cursor: pointer
        z-index: 6
        +btn
    &__full
        opacity: 1
        position: fixed
        top: 0
        right: 0
        width: 50%
        height: 100%
        +media-until(1279px)
            width: 60%
            & img
                object-position: 60%
        & .inside__image-points
            size: 100%
            top: 0
            left: 0
            position: absolute
            z-index: 2
            & .point
                border-color: white
                &__content
                    &::after
                        filter: brightness(10)
                    & p
                        text-shadow: 0 0 5px rgba(0, 0, 0, .4)
                        font-weight: bold
                        color: white
                        &::before, &::after
                            background-color: white
        & img
            size: 100%
            object-fit: cover
            z-index: 0
        &::after
            position: absolute
            content: ''
            top: 0
            left: 0
            size: 100%
            background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.56) 100%)
            pointer-events: none
            z-index: 1
    & .wrapper
        flex-grow: 1
        display: grid
        align-items: center
        grid-template-columns: 355px 1fr
    &__image
        margin: 0 auto
        position: relative
        +media-until(1279px)
            max-width: 600px
        & img
            +media-until(1279px)
                width: 100%
                max-width: 100%
        &-points
            & .point
                border-color: #D4D9E6
                &__content
                    &::after
                        background: url("data:image/svg+xml,%3Csvg width='6' height='7' viewBox='0 0 6 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.41406 3.70703L0.898594 6.60574L1.89859 3.70703L0.898594 0.808327L5.41406 3.70703Z' fill='%231D4CB4' /%3E%3C/svg%3E") no-repeat center
                    & p
                        font-weight: bold
                        color: $main
                        &::before, &::after
                            background-color: #D4D9E6
    
    &__buttons
        flex-shrink: 0
        display: flex
        flex-direction: column
        position: relative
        min-width: 230px
        &::before
            content: ''
            position: absolute
            bottom: 0
            left: 0
            width: 200%
            height: 1px
            background: linear-gradient(90deg, #D4D9E6 0%, rgba(212, 217, 230, 0) 102.22%)
        &-item
            position: relative
            display: flex
            align-items: center
            font-size: 14px
            text-transform: uppercase
            padding: 18px 0
            cursor: pointer
            transition: color .3s
            &.active, &:hover
                color: $main
            &::before
                content: ''
                position: absolute
                top: 0
                left: 0
                width: 200%
                height: 1px
                background: linear-gradient(90deg, #D4D9E6 0%, rgba(212, 217, 230, 0) 102.22%)
            
            &::after
                transition: transform .3s
                content: ''
                size: 7px
                margin-left: 13px
                background: url("data:image/svg+xml,%3Csvg width='6' height='7' viewBox='0 0 6 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.41406 3.70703L0.898594 6.60574L1.89859 3.70703L0.898594 0.808327L5.41406 3.70703Z' fill='black' /%3E%3C/svg%3E") no-repeat center
                background-size: contain

@keyframes fade
    0%
        opacity: 0
    
    100%
        opacity: 1

.fade-enter-active, .fade-leave-active
    opacity: 1
    transition: opacity 1s

.fade-enter, .fade-leave-to
    opacity: 0
