.turner
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: linear-gradient(90deg, #1D4CB4 0%, #468CD3 100%)
    z-index: 100
    display: flex
    align-items: center
    flex-direction: column
    justify-content: center
    &__image
        size: 504px
        display: flex
        align-items: center
        justify-content: center
        border: 1px solid rgba(255, 255, 255, 0.2)
        box-sizing: border-box
        border-radius: 50%
        margin-bottom: 129px
        & svg
            size: 50%
            animation: phoneRotate .7s  alternate infinite
    &__text
        font-weight: normal
        font-size: 50px
        line-height: 1.479
        text-align: center
        color: #FFFFFF




@keyframes phoneRotate
    0%
        transform: rotate(0)

    100%
        transform: rotate(90deg)