// @font-face
//     font-family: 'CeraPro'
//     src: url('../fonts/CeraPro/CeraPro-Light.woff2') format('woff2'), url('../fonts/CeraPro/CeraPro-Light.woff') format('woff')
//     font-weight: 300
//     font-style: normal
//     font-display: swap

@font-face
    font-family: 'CeraPro'
    src: url('../fonts/CeraPro/CeraPro-Regular.woff2') format('woff2'), url('../fonts/CeraPro/CeraPro-Regular.woff') format('woff')
    font-weight: 400
    font-style: normal
    font-display: swap

@font-face
    font-family: 'CeraPro'
    src: url('../fonts/CeraPro/CeraPro-Medium.woff2') format('woff2'), url('../fonts/CeraPro/CeraPro-Medium.woff') format('woff')
    font-weight: 500
    font-style: normal
    font-display: swap

// @font-face
//     font-family: 'CeraPro'
//     src: url('../fonts/CeraPro/CeraPro-SemiBold.woff2') format('woff2'), url('../fonts/CeraPro/CeraPro-SemiBold.woff') format('woff')
//     font-weight: 600
//     font-style: normal
//     font-display: swap

@font-face
    font-family: 'CeraPro'
    src: url('../fonts/CeraPro/CeraPro-Bold.woff2') format('woff2'), url('../fonts/CeraPro/CeraPro-Bold.woff') format('woff')
    font-weight: 700
    font-style: normal
    font-display: swap
