.main-screen
    margin-bottom: 0
    flex-grow: 1
    display: flex
    align-items: flex-end
    padding-top: 130px
    padding-bottom: 135px
    height: 100%
    &__video
        position: absolute
        pointer-events: none
        user-select: none
        top: 0
        left: 0
        size: 100%
        z-index: 2
        object-fit: cover
    &::before
        top: 0
        left: 0
        z-index: 3
        content: ''
        size: 100%
        background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.56) 100%)
        position: fixed
    & > .wrapper
        max-width: 1612px
        z-index: 4
        height: 100%
        justify-content: flex-end
    &__content
        animation: fadeinUp .9s
        display: flex
        align-items: flex-end
        justify-content: space-between
        width: 100%
        & + small
            margin-top: 55px
            color: white
            opacity: .5
            font-size: 14px
            
    &__text
        max-width: 820px
        color: white
        & p
            max-width: 400px
        & h1
            margin-bottom: 34px
    &__buttons
        display: flex
        flex-direction: column
        align-items: flex-end
        & a
            cursor: pointer
            +btn-arrow
            &:not(:last-of-type)
                margin-bottom: 25px
