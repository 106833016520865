.outside
    z-index: 2
    position: absolute
    top: 0
    left: 0
    size: 100%
    background: no-repeat center
    background-color: black
    background-size: cover
    animation: fadeIn3 .4s
    
@keyframes fadeIn3
    0%
        opacity: 0
    100%
        opacity: 1