.point
    position: absolute
    display: flex
    align-items: center
    justify-content: center
    border: 1px solid rgba(255, 255, 255, 0.3)
    border-radius: 50%
    size: 80px
    transform: scale(0.9)
    transition: transform .4s .1s
    cursor: pointer
    opacity: 0
    +media-until(1450px)
        size: 60px
    &::after
        pointer-events: none
        content: ""
        position: absolute
        width: 100%
        height: 100%
        top: 0
        left: 0
        z-index: -1
        opacity: 0
        transform: translate(-50%,-50%)
        border: 3px solid rgba(255,255,255,0.1)
        animation: pulse 2s linear infinite
        border-radius: 50%
        box-shadow: inset 0px 0px 15px 10px rgba(30, 66, 143, 0.15)

    &:nth-of-type(1)
        animation: point .6s forwards
    &:nth-of-type(2)
        animation: point .6s .2s forwards
    &:nth-of-type(3)
        animation: point .6s .4s forwards
    &:nth-of-type(4)
        animation: point .6s .6s forwards
    &:nth-of-type(5)
        animation: point .6s .8s forwards
    &:nth-of-type(6)
        animation: point .6s 1s forwards
    &:nth-of-type(7)
        animation: point .6s 1.2s forwards

    &:hover
        transform: scale(1)
        &::after
            display: none
        & .point
            &__content
                clip-path: inset(0 0 -100px 0)
    &__circle
        size: 50px
        border-radius: 50%
        background: linear-gradient(360deg, #1D4CB4 0%, #468CD3 100%)
        filter: drop-shadow(0 0px 10px $main)
        display: flex
        align-items: center
        justify-content: center
        +media-until(1680px)
            size: 40px
        +media-until(1450px)
            size: 30px

        & span
            width: 16px
            height: 2px
            background-color: #fff
            +media-until(1680px)
                width: 10px
            &:last-of-type
                position: absolute
                transform: rotate(-90deg)
    &__content
        position: absolute
        top: -60px
        left: 53px
        padding-left: 80px
        transition: clip-path .5s ease-in
        clip-path: inset(0 100% -100px 0)
        display: flex
        align-items: center
        +media-until(1680px)
            padding-left: 50px
        +media-until(1450px)
            top: 58px
            left: 30px
            padding-left: 30px

        &::after
            transition: transform .3s
            content: ''
            size: 7px
            margin-left: 13px
            background: url("data:image/svg+xml,%3Csvg width='6' height='7' viewBox='0 0 6 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.41406 3.70703L0.898594 6.60574L1.89859 3.70703L0.898594 0.808327L5.41406 3.70703Z' fill='white' /%3E%3C/svg%3E") no-repeat center
            background-size: contain
        & p
            position: relative
            color: white
            font-size: 14px
            font-weight: bold
            white-space: nowrap
            &::before
                content: ''
                position: absolute
                left: 0
                width: 100%
                top: calc(100% + 5px)
                transition: width .4s .4s linear
                height: 1px
                background: rgba(255, 255, 255, 0.3)
            &::after
                content: ''
                position: absolute
                transform: rotate(-45deg)
                width: 80px
                left: -69px
                top: calc(100% + 33px)
                transition: width .4s linear
                height: 1px
                background: rgba(255, 255, 255, 0.3)
                +media-until(1680px)
                    width: 55px
                    top: calc(100% + 25px)
                    left: -47px
                +media-until(1450px)
                    width: 40px
                    top: 13px
                    transform: rotate(45deg)
                    left: -34px

@keyframes point
    0%
        opacity: 0
        transform: scale(0)

    30%
        opacity: 1

    50%
        transform: scale(1.1)
    100%
        opacity: 1
        transform: scale(1)

@keyframes pulse

    0%
        transform: scale(.6)
        opacity: 0

    50%

        transform: scale(.9)
        opacity: 0

    60%

        transform: scale(1.1)
        opacity: .6

    70%

        transform: scale(1.3)
        opacity: .85

    80%

        transform: scale(1.4)
        opacity: .6

    100%

        transform: scale(1.6)
        opacity: 0
