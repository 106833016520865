.main-tabs
    display: flex
    animation: fadeinUp .5s
    &.inside-buttons
        & .main-tabs__item
            color: $black
            border-color: #D4D9E6
            &.active
                background-color: #E1E5F2
                border-color: transparent
    
    & .wrapper
        height: 0
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
    &__item
        font-size: 14px
        cursor: pointer
        letter-spacing: 0.06em
        display: flex
        align-items: center
        color: white
        padding: 0 30px
        height: 60px
        transition: background-color .3s, border-color .3s
        text-transform: uppercase
        border-radius: 30px
        border: 1px solid rgba(255, 255, 255, 0.4)
        +media-until(1450px)
            height: 48px
        &.active
            backdrop-filter: blur(5px)
            border-color: transparent
            background: rgba(255, 255, 255, 0.4)
        &:not(:last-of-type)
            margin-right: 35px

@keyframes fadeinUp
    0%
        opacity: 0
        transform: translateY(30px)
    
    100%
        opacity: 1
        transform: translateY(0px)
